import { Routes } from '@angular/router';
import { InitializationGuard } from '@guards/initialization.guard';
import { PAGE_ROUTES } from '@enums/page-routes.enum';
import { CompleteOnBoardingGuard } from '@guards/complete-on-boarding.guard';
import { AuthGuard } from '@guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: PAGE_ROUTES.CREATE_ACCOUNT,
    canActivate: [InitializationGuard, CompleteOnBoardingGuard],
    loadChildren: () =>
      import('./pages/create-account/create-account.module').then(
        (m) => m.CreateAccountModule,
      ),
  },
  {
    path: PAGE_ROUTES.AUTH,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/not-auth/not-auth.module').then((m) => m.NotAuthModule),
  },
  {
    path: PAGE_ROUTES.LOADING,
    loadChildren: () =>
      import('./pages/loading/loading.module').then((m) => m.LoadingModule),
  },

  { path: '**', redirectTo: '', pathMatch: 'full' },
];
