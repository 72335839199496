import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthApiService } from '@api/auth-api.service';
import { LOCAL_STORAGE } from '@enums/local-storage.enum';
import { UnsubscribeBase } from '@core-utils';

@Injectable()
export class NavigationService extends UnsubscribeBase {
  private isFirstNavigation = true;

  constructor(
    private router: Router,
    private authApiService: AuthApiService,
  ) {
    super();
  }

  initializeNavigation() {
    this.addSubscription = this.router.events.subscribe((event) => {
      if (
        this.isFirstNavigation &&
        !this.authApiService.hasValidAccessToken() &&
        event instanceof NavigationStart
      ) {
        localStorage.setItem(LOCAL_STORAGE.NAVIGATION_URL, event.url);
        this.isFirstNavigation = false;
      }
    });
  }

  async handleNavigationAfterLogin() {
    const firstNavigationUrl = localStorage.getItem(
      LOCAL_STORAGE.NAVIGATION_URL,
    );
    if (firstNavigationUrl) {
      await this.router.navigateByUrl(firstNavigationUrl || '/');
      localStorage.removeItem(LOCAL_STORAGE.NAVIGATION_URL);
    }
    return Promise.resolve();
  }
}
