import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { routes } from './app.routes';
import { AppInterceptor } from '@services/app.interceptor';
import { provideToastr, ToastrService } from 'ngx-toastr';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { ThemeModeService } from '@services/theme-mode.service';
import { provideTransloco } from '@jsverse/transloco';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SwitchLanguageService } from '@services/switch-language.service';
import { AuthApiService } from '@api/auth-api.service';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from '@services/layout.service';
import { OAuthService, provideOAuthClient } from 'angular-oauth2-oidc';
import { CustomToastrService, TranslocoHttpLoader } from '@core-utils';
import { UsersApiService } from '@api/users-api.service';
import { authCodeFlowConfig } from '@config/auth.config';
import { UserService } from '@services/user.service';
import { NavigationService } from '@services/navigation.service';
import { LOCAL_STORAGE } from '@enums/local-storage.enum';

function appInitializer(
  authApiService: AuthApiService,
  userService: UserService,
  oauthService: OAuthService,
  navigationService: NavigationService,
) {
  return () => {
    return new Promise<void>((resolve) => {
      configureOAuth(oauthService);
      authApiService.refreshTokenAfterSleep();
      authApiService.updateExpireToken();
      navigationService.initializeNavigation();

      oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
        const token = authApiService.hasValidAccessToken();
        if (token) {
          userService.getUserProfile().subscribe(() => {
            navigationService.handleNavigationAfterLogin().then(() => {
              resolve();
            });
          });
        } else {
          localStorage.removeItem(LOCAL_STORAGE.NAVIGATION_URL);
          resolve();
        }
      });
    });
  };
}

function configureOAuth(oauthService: OAuthService) {
  oauthService.configure(authCodeFlowConfig);
  oauthService.setupAutomaticSilentRefresh();
}

export const appConfig: ApplicationConfig = {
  providers: [
    ThemeModeService,
    SwitchLanguageService,
    AuthApiService,
    UsersApiService,
    UserService,
    NavigationService,
    NgbActiveOffcanvas,
    LayoutService,
    OAuthService,
    provideOAuthClient(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideToastr(),
    provideHttpClient(withInterceptors([AppInterceptor])),
    importProvidersFrom([BrowserAnimationsModule]),
    provideEnvironmentNgxMask({
      validation: false,
    }),
    provideTransloco({
      config: {
        availableLangs: ['en', 'fr'],
        defaultLang: SwitchLanguageService.getStoredLanguage(),
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthApiService, UserService, OAuthService, NavigationService],
    },
    {
      provide: CustomToastrService,
      useClass: CustomToastrService,
      deps: [ToastrService],
    },
  ],
};
