import { CanActivateFn, Router } from '@angular/router';
import { AuthApiService } from '@api/auth-api.service';
import { inject } from '@angular/core';

export const AuthGuard: CanActivateFn = (): boolean => {
  const authService = inject(AuthApiService);
  const router = inject(Router);

  if (authService.accessToken) {
    router.navigate(['/']).then();
    return false;
  }
  return true;
};
