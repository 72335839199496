import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthApiService } from '@api/auth-api.service';

export const CompleteOnBoardingGuard: CanActivateFn = (): boolean => {
  const authService = inject(AuthApiService);
  const router = inject(Router);

  if (authService.skipOnboarding) {
    router.navigate(['/']).then();
    return false;
  }
  return true;
};
