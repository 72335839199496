import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { inject } from '@angular/core';
import { AuthApiService } from '@api/auth-api.service';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { PAGE_ROUTES } from '@enums/page-routes.enum';

export const AppInterceptor: HttpInterceptorFn = (req, next) => {
  let BASE_URL = environment.api;
  const authApiService = inject(AuthApiService);
  const toastrService = inject(ToastrService);
  const router = inject(Router);

  // For test view Onboarding(need to remove in future)
  if (req.url.includes('Onboarding')) {
    BASE_URL = 'https://mjpointsapi-387888704906.us-central1.run.app/api/v1/';
  }

  const isProtectedEndpoint = ['auth.mjpoints.com', 'assets'].some((url) =>
    req.url.includes(url),
  );

  if (!isProtectedEndpoint) {
    const headers: { [name: string]: string } = {};

    const token = authApiService.accessToken;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    if (req.method === 'POST') {
      headers['Idempotency-Key'] = uuidv4();
    }

    const authReq = req.clone({
      setHeaders: headers,
      url: `${BASE_URL}${req.url}`,
    });

    return next(authReq).pipe(
      catchError(({ error, status }: HttpErrorResponse) => {
        let errorMessage =
          'An unexpected error occurred. Please try again later.';

        if (status === 401) {
          router.navigate(['/' + PAGE_ROUTES.LOADING]).then(() => {
            authApiService.initLoginFlow();
          });
        }

        if (error.detail) {
          errorMessage = error.detail;
        }

        if (error.errors?.length > 0) {
          errorMessage = error.errors[0].description;
        }

        toastrService.error(errorMessage, 'Error');

        return throwError(() => error);
      }),
    );
  }

  return next(req);
};
